import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../slices/apiSlice";
import loginSlice from "../slices/Loginxxx/loginSlice";
import errorsSlice from "../slices/Errorsxx/errorsSlice";
import trkgfileSlice from "../slices/Trkgfile/trkgfileSlice";
import autocomSlice from "../slices/autocomxSlice/autocomSlice";
import { cimaxxxxApiSlice } from "../slices/Cimaxxxx/cimaxxxxApiSlice";
import cimaxxxSlice from "../slices/Cimaxxxx/cimaxxxxSlice";

//Redux persist
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";

import thunk from "redux-thunk";

const persistConfig = {
  key: "login",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, loginSlice);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [cimaxxxxApiSlice.reducerPath]: cimaxxxxApiSlice.reducer,
    persistedReducer,
    errors: errorsSlice,
    trkgfile: trkgfileSlice,
    autocomp: autocomSlice,
    cimaxxxx: cimaxxxSlice,
  },
  devTools: process.env.REACT_APP_GESTOR_DEBUG,
  middleware: [thunk, apiSlice.middleware, cimaxxxxApiSlice.middleware],
});

export const persistor = persistStore(store);
