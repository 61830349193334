import { useEffect, useState } from "react";
import  { Form, InputGroup, Button } from "react-bootstrap";
import { PersonCircle, Lock, EyeFill } from "react-bootstrap-icons";
import { useForm} from "react-hook-form";
import { useDataInfoUserMutation, useLoginMutation } from "../../libs/redux/slices/Loginxxx/loginApiSlice";
import { signIn } from "../../libs/redux/slices/Loginxxx/loginSlice";
import { useDispatch } from "react-redux";
import Changpas from "../Changpas/Changpas";
import { useLoginCimaMutation } from "../../libs/redux/slices/Cimaxxxx/cimaxxxxApiSlice";
import { setTokenCima } from "../../libs/redux/slices/Cimaxxxx/cimaxxxxSlice";

const Loginxxx =() => {
  const dispatch = useDispatch();
  const [ password, setPassword ] = useState(false);
  const [ login, { isLoading } ] = useLoginMutation();
  const [ changePsw, setChangePsw] = useState(false);
  const [ userId, setUserId ] = useState("");
  const [ datainfoUSer, { isLoading: loadInfoUsr} ] = useDataInfoUserMutation();
  const [ logCima ] = useLoginCimaMutation();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
  } = useForm();

  useEffect(() => {
    const validateTkn = async () => {
      await datainfoUSer().then((userData) => {
        if (userData?.data?.data?.[0].INFOUSER.USRIDXXX) {
          const dataUser = userData.data.data?.[0];
          const userName = userData.data.data?.[0].INFOUSER.USRIDXXX;
          dispatch(signIn({ userName, dataUser }));
        }
      }).catch((e) => {console.log(e)})
    }
    validateTkn();
    // eslint-disable-next-line
  }, [])

  const f_login = async (useFormData) => {
    const {username} = useFormData;
    setUserId(username);
    await login(useFormData)
      .unwrap()
      .then(async (userData) => {
        if (userData?.data?.[0].INFOUSER) {
          const dataUser = userData?.data?.[0];
          dispatch(signIn({ username, dataUser }));
          const { data } = await logCima({ "USRIDXXX": userData?.data?.[0].INFOUSER.USRIDXXX.toString() });
          if ( data ) {
            dispatch(setTokenCima(data.data[0].token));
          }
        }
      }).catch((e) => {
        if ( e.data.errors.message === "Contraseña Vencida.") {
          setChangePsw(true);
        }
      })
  }

  return changePsw ? (
    <Changpas userId={userId}/>
  ) : (
    <Form className="text-center w-75 mx-auto" onSubmit={handleSubmit(f_login)}>
      <InputGroup className="mt-4">
        <InputGroup.Text id="username">
          <PersonCircle />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Cedula o Correo"
          aria-label="username"
          aria-describedby="username"
          { ...register('username', { required: true})}
          isInvalid={errors.username}
        />
        <Form.Control.Feedback type="invalid">Debe Diligenciar el Usuario</Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-4">
        <InputGroup.Text id="idPassword">
          <Lock />
        </InputGroup.Text>
        <Form.Control
          type={!password ? "password" : "text"}
          placeholder="Contraseña"
          aria-label="idPassword"
          aria-describedby="idPassword"
          { ...register('Password', { required: true})}
          isInvalid={errors.Password}
        />
        <InputGroup.Text>
          <EyeFill onClick={() => setPassword(!password)}/>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">Debe Diligenciar la Contraseña</Form.Control.Feedback>
      </InputGroup>
      {
        isLoading || loadInfoUsr ? (
          <Button type="button" className="mt-4 mb-4 btn-login">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Cargando...
          </Button>
        ) : (
          <Button type="submit" className="mt-4 mb-4 btn-login">
            INICIAR SESION
          </Button>
        )
      }
    </Form>
  );
}

export default Loginxxx;
