import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: "",
    isLoggedUserPass: false,
    isLoggedIn: false,
    infoUser: null,
    token: null,
    permUser: null,
  },
  reducers: {
    signIn: ( state, action ) => {
      const { token } = action.payload.dataUser;
      const { INFOUSER } = action.payload.dataUser;
      const { USRIDXXX } = action.payload.dataUser.INFOUSER;
      state.user = USRIDXXX.toString();
      state.infoUser = INFOUSER;
      state.permUser = INFOUSER.USERPERM;
      state.token = token;
      state.isLoggedIn = true;
    },
    signOut: (state) => {
      state.user = null;
      state.infoUser = null;
      state.token = null;
      state.isLoggedIn = false;
      state.permUser = null;
    }
  }
});

export const {
  signIn,
  signOut,
} = loginSlice.actions;

export default loginSlice.reducer;

export const getisLoggedIn = (state) => {
  return state.persistedReducer.isLoggedIn;
};
export const getUser = (state) => {
  return state.persistedReducer.user;
};
export const getpermUser = (state) => {
  return state.persistedReducer.permUser;
};
export const getinfoUser = (state) => {
  return state.persistedReducer.infoUser;
}
