import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signOut } from "./Loginxxx/loginSlice";
import { setErrorMessage, setShowErrors, setTypeMessage } from "./Errorsxx/errorsSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_GESTOR_ENDPOINT,
  mode: "cors",
  timeout: process.env.REACT_APP_GESTOR_TIMEOUT,
  credentials: "include",
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().persistedReducer.token;
    Headers.set("Accept", "application/json");
    if (token) {
      Headers.set("Authorization", `Bearer ${token}`);
    }
    return Headers;
  },
});

const baseQueryWithLogOut = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let message = null;
  let type = "";
  switch (result?.error?.status) {
    case 401:
      api.dispatch(signOut());
      break;
    case 403:
    case 422:
    case 500:
    case 405:
      message = { ...result?.error?.data?.errors };
      type = "Error";
      break;
    default:
      break;
  }
  if (message) {
    api.dispatch(setShowErrors(true));
    api.dispatch(setErrorMessage(message));
    api.dispatch(setTypeMessage(type));
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithLogOut,
  endpoints: (builder) => ({}),
  tagTypes: ["Create", "Consult"],
});
