import { useSelector } from "react-redux";
import {
  getErrorMessage,
  getShowErrors,
  getTypeMessage,
  setIsConfirm,
  setShowErrors,
} from "../../libs/redux/slices/Errorsxx/errorsSlice";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useDispatch } from "react-redux";
import { SquareFill } from "react-bootstrap-icons";
import colors from "../../scss/colors.scss";
import { Button } from "react-bootstrap";

const Errorsxx = () => {
  const dispatch = useDispatch();
  const showToast = useSelector(getShowErrors);
  const errors = useSelector(getErrorMessage);
  const typeMessage = useSelector(getTypeMessage);
  let messageError = "";
  const propertys = Object.getOwnPropertyNames(errors);
  if (errors?.message) {
    messageError = errors?.message;
    //Validacion mensaje por llave primaria
    if (errors?.exception && errors?.exception.indexOf("Contraseña Vencida") > -1) {
      messageError = "Contraseña Vencida";
    }
  } else {
    if (errors?.USRPASXX && errors?.USRPASXX.indexOf("The u s r p a s x x must contain at least one uppercase and one lowercase letter.") > -1) {
      messageError = "La nueva contraseña debe contener almenos una Mayuscula y Minuscula, Verifique!";
    } else {
      propertys.forEach((property) => {
        messageError += errors[property] + "\n";
      });
    }
  }
  let color = "";
  let colorBody = "";
  let backgroundBody = "";
  let hideToast = true;
  switch (typeMessage) {
    case "Error":
      color = "red";
      colorBody = colors.colorMessageError;
      backgroundBody = colors.colorBodyError;
      break;
    case "Confirmacion":
      color = "green";
      colorBody = colors.colorMessageConfirm;
      backgroundBody = colors.colorBodyConfirm;
      break;
    case "Aviso":
      hideToast = false;
      color = "blue";
      colorBody = colors.colorMessageWarning;
      backgroundBody = "";
      break;
    default:
      color = "red";
      break;
  }
  return (
    <ToastContainer className="p-3" position="middle-center">
      <Toast onClose={() => dispatch(setShowErrors(false))} show={showToast} delay={3000} autohide={hideToast}>
        <Toast.Header>
          <SquareFill size={20} color={color} />
          <strong className="me-auto" style={{ color: "black", marginLeft: "10px" }}>
            {typeMessage}
          </strong>
        </Toast.Header>
        <Toast.Body
          style={{
            color: colorBody,
            backgroundColor: backgroundBody,
            fontWeight: "bold",
          }}
        >
          {messageError}
          {!hideToast && (
            <Toast.Body className="text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  dispatch(setIsConfirm(true));
                  dispatch(setShowErrors(false));
                }}
              >
                Confirmar
              </Button>
            </Toast.Body>
          )}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Errorsxx;
