import { apiSlice } from "../apiSlice";

export const loginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (parameters) => ({
        url: `/login`,
        method: "POST",
        body: {
          USRIDXXX: parameters.username,
          USRPASXX: parameters.Password,
          SISTEMAX: "GESTOR",
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
    changePws: builder.mutation({
      query: (parameters) => ({
        url: "/changePassword",
        method: "POST",
        body: {
          USRIDXXX: parameters.username,
          USRPASXX_OLD: parameters.PasswordOld,
          USRPASXX: parameters.PasswordNew,
        },
      }),
    }),
    dataInfoUser: builder.mutation({
      query: () => ({
        url: `/userinfo?system=GESTOR`,
        method: "GET",
      })
    }),
    loginUserParams: builder.mutation({
      query: (parameters) => ({
        url: "/login?external=true",
        method: "POST",
        body: {
          USRIDXXX: parameters.user,
          USRPASXX: "1",
          SISTEMAX: "GESTOR",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useChangePwsMutation,
  useDataInfoUserMutation,
  useLoginUserParamsMutation } = loginApiSlice;
