import { Row } from "react-bootstrap";
import Loginxxx from "../components/Loginxxx";

const LoginPage = () => {
  const fecha = new Date();
  const añoActual = fecha.getFullYear();

  return (
    <div className="classLogin">
      <div className="login-page d-flex flex-column justify-content-center align-items-center">
        <Row className="p-3">
          <img className="genio-logo"
            src="/images/genio-documental-logo.png" alt="Visor Documental"
          />
        </Row>
        <Row className="col-md-5 col-lg-6 col-xs-12">
          <div className="forms rounded p-3">
            <Loginxxx />
          </div>
        </Row>
        <footer className="text-center text-white fixed-bottom btn-login">
          <div className="text-center p-3 btn-login">
            {añoActual} Repremundo
          </div>
        </footer>
      </div>
    </div>
  )


}
export default LoginPage;
