import { Container, Modal, Carousel, Stack, Form, InputGroup } from "react-bootstrap";
import {
  FiPlusCircle,
  FiMinusCircle,
  FiRotateCw,
  FiRotateCcw,
  FiDownload,
  FiPrinter,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import Loadingx from "../Loadingx/Loadingx";
import { useState, useEffect, useRef, createRef } from "react";
import { useSelector } from "react-redux";
import { useLazyGetFilesFiltersQuery } from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import { getinfoUser } from "../../libs/redux/slices/Loginxxx/loginSlice";
import "./Viewerstyles.css";
import { Percent } from "react-bootstrap-icons";

const Modaview = ({ modalView, setModalView, jsonFilters, currentImage, dataEdit }) => {
  const [getFilesxfilters] = useLazyGetFilesFiltersQuery();
  const [totalImages, setTotalImages] = useState(0);
  const [dataImages, setDataImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [codProducto, setCodProducto] = useState("");
  const [factor, setFactor] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });
  const infoUser = useSelector(getinfoUser);
  const [print, setPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [levelsZoom, setLevelsZoom] = useState([]); // Estado inicial zoom
  const [imageSize, setImageSize] = useState([{ width: 0, height: 0 }]);
  const imagesRef = useRef([]);
  const scaleZoom = parseFloat(10);

  const handleSelect = (indexSel) => {
    setIndex(indexSel);
    setCodProducto(dataImages[indexSel].JSONMETX.CODPRODU);
    setFactor(1);
    setContainerPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (jsonFilters) {
      getFilesxfilters({ filters: jsonFilters }, { preferCacheValue: false })
        .unwrap()
        .then((response) => {
          let data = response.data;
          const ind = data.findIndex((item) => item.DOCDURLX === currentImage);
          const objCurrent = data[ind];
          if (ind !== -1) {
            data = data.filter((_, i) => i !== ind);
            data.unshift(objCurrent);
          }
          setTotalImages(data.length);
          setDataImages(
            data.map((item) => ({
              ...item,
              rotation: 0, // seteo inicial 0 deg
            }))
          );
          setCodProducto(data[0].JSONMETX.CODPRODU);
          setImageSize(data.map(() => ({ width: 0, height: 0 })));
          setLevelsZoom(data.map(() => ({ zoom: 100 })));
          imagesRef.current = data.map((_, i) => imagesRef.current[i] ?? createRef());
        });
    }
  }, [jsonFilters, getFilesxfilters, currentImage]);

  const toggleMaximize = () => {
    setFactor((prevFactor) => (prevFactor <= 2 ? prevFactor + scaleZoom : prevFactor));
    const actualZoom = levelsZoom[index].zoom;
    setLevelsZoom((prevZoom) => ({ ...prevZoom, [index]: { zoom: parseFloat(actualZoom) + parseFloat(scaleZoom) } }));
  };

  const toggleMinimize = () => {
    setFactor((prevFactor) => (prevFactor > 1 ? prevFactor - scaleZoom : prevFactor));
    const actualZoom = levelsZoom[index].zoom;
    setLevelsZoom((prevZoom) => ({ ...prevZoom, [index]: { zoom: parseFloat(actualZoom) - parseFloat(scaleZoom) } }));
  };

  const rotateLeftImage = () => {
    const newDataImages = [...dataImages];
    newDataImages[index] = {
      ...newDataImages[index],
      rotation: (newDataImages[index].rotation - 90 + 360) % 360,
    };
    setDataImages(newDataImages);
    handleSetSizeImage();
  };

  const rotateRightImage = () => {
    const newDataImages = [...dataImages];
    newDataImages[index] = {
      ...newDataImages[index],
      rotation: (newDataImages[index].rotation + 90) % 360,
    };
    setDataImages(newDataImages);
    handleSetSizeImage();
  };

  //Funcion para validar el tamaño actual de una imagen - util para la rotacion
  const handleSetSizeImage = () => {
    if (imagesRef.current[index]) {
      const { width, height } = imagesRef.current[index].current.getBoundingClientRect();
      setImageSize((state) => ({ ...state, [index]: { width, height } }));
    }
  };

  const handleMouseDown = (e) => {
    if (factor > 1) {
      setDragging(true);
      setPosition({
        x: e.clientX - containerPosition.x,
        y: e.clientY - containerPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      e.preventDefault();
      const newPosX = e.clientX - position.x;
      const newPosY = e.clientY - position.y;
      setContainerPosition({ x: newPosX, y: newPosY });
    }
  };

  const downloadCurrentImage = () => {
    const currentImageData = dataImages[index];
    fetch(currentImageData.DOCDURLX, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = currentImageData.DOCDURLX.substring(currentImageData.DOCDURLX.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.log("No se descargo la imagen"));
  };

  //Funcion para imprimir una imagem
  const printImage = () => {
    const currentImageData = dataImages[index];
    let urlImage = currentImageData.DOCDURLX;
    const url = currentImageData.DOCDURLX.split("/");
    let image = document.createElement("img");
    image.src = urlImage;
    //Funcion para el precargado de la imagen
    image.onload = function () {
      //Abro un nueva ventana
      let openWindow = window.open("", "_blank");
      if (openWindow) {
        //Agrego el contenido de la imagen en una nueva ventana
        openWindow.document.write(
          "<html><head><title>" +
            url[url.length - 1] +
            '</title></head><center><img src="' +
            urlImage +
            '"></center></html>'
        );
        //Imprimo el contenido
        openWindow.print();
        openWindow.close();
      }
    };
  };

  //Funcion para validar si un usuario tiene permisos para imprimir
  useEffect(() => {
    if (infoUser && infoUser.USERPERM) {
      const { USERPERM } = infoUser;
      const { AREAIDXX, SERIEIDX, TIPOIDXX } = dataEdit;
      let isPrint = false;
      let isDownload = false;
      USERPERM.forEach((element) => {
        const { GEST0001 } = element;
        //Filtro por el area del documento
        const filterArea = GEST0001.filter((item) => item.AREAIDXX === AREAIDXX);
        filterArea.forEach((areas) => {
          const { GEST0002 } = areas;
          //Filtro por la serie del documento
          const filterSerie = GEST0002.filter((item) => item.SERIEIDX === SERIEIDX);
          filterSerie.forEach((series) => {
            const { GEST0003 } = series;
            //Filtro por el tipo del documento
            const filterType = GEST0003.filter((item) => item.TIPOIDXX === TIPOIDXX);
            if (filterType[0] && filterType[0]?.PRINTXXX === "SI") {
              isPrint = true;
            }
            if (filterType[0] && filterType[0]?.DOWNLOAD === "SI") {
              isDownload = true;
            }
          });
        });
      });
      setPrint(isPrint);
      setIsDownload(isDownload);
    }
  }, [infoUser, dataEdit]);

  const handleZoomChange = (event) => {
    setLevelsZoom((state) => ({ ...state, [index]: { zoom: event.target.value } }));
  };

  return (
    <>
      <Modal show={modalView} onHide={() => setModalView(false)} size="lg">
        <Modal.Header closeButton className="modal-header">
          {dataImages.length > 0 ? (
            <Container>
              <Stack direction="horizontal" gap={3}>
                <div className="p-2" style={{ color: "#fd7e14" }}>
                  <b>COD:</b> {codProducto}
                </div>
                <div
                  className="p-2 ms-auto"
                  style={{ color: "#fd7e14", display: "flex", alignContent: "space-between" }}
                >
                  <div style={{ alignContent: "center" }} className="me-2">
                    {index + 1} / {totalImages}
                  </div>
                  <div style={{ alignContent: "center" }}>
                    <InputGroup className="mt-4">
                      <Form.Control
                        type="text"
                        value={`${levelsZoom[index] && levelsZoom[index].zoom}`}
                        onChange={handleZoomChange}
                        maxLength="3"
                        style={{
                          width: "70px",
                          borderColor: "#fd7e14",
                          flex: "none",
                          position: "relative",
                          top: "-12px",
                          textAlign: "center",
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="p-2">
                  <FiPlusCircle onClick={toggleMaximize} className="custom-buttons" title="aumentar" />
                  <FiMinusCircle onClick={toggleMinimize} className="custom-buttons" title="disminuir" />
                </div>
                <div className="">
                  <FiRotateCcw onClick={rotateLeftImage} className="custom-buttons" title="rotar izq" />
                  <FiRotateCw onClick={rotateRightImage} className="custom-buttons" title="rotar der" />
                </div>
                {isDownload && (
                  <div className="p-2">
                    <FiDownload onClick={downloadCurrentImage} className="custom-buttons" title="descargar" />
                  </div>
                )}
                {print && (
                  <div className="p-2">
                    <FiPrinter onClick={printImage} className="custom-buttons" title="imprimir" />
                  </div>
                )}
              </Stack>
            </Container>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          {dataImages.length > 0 ? (
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              prevIcon={<FiArrowLeftCircle color="black" size={33} />}
              nextIcon={<FiArrowRightCircle color="black" size={33} />}
            >
              {dataImages.map((data, i) => (
                <Carousel.Item key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height:
                        imageSize[i] && imageSize[i].width > 0
                          ? imageSize[i].width > imageSize[i].height
                            ? `${imageSize[i].width}px`
                            : `${imageSize[i].height}px`
                          : "",
                      width:
                        imageSize[i] && imageSize[i].width > 0
                          ? imageSize[i].width > imageSize[i].height
                            ? `${imageSize[i].width}px`
                            : `${imageSize[i].height}px`
                          : "",
                      margin: "auto",
                      cursor: factor > 1 ? "move" : "auto",
                      overflow: factor > 1 ? "auto" : "hidden",
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                    <img
                      ref={imagesRef.current[i]}
                      src={data.DOCDURLX}
                      alt={`Item ${i}`}
                      style={{
                        transform: `rotate(${data.rotation}deg) scale(${
                          levelsZoom[index] ? levelsZoom[index].zoom / 100 : 100
                        }) translate(${containerPosition.x}px, ${containerPosition.y}px)`,
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Loadingx />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modaview;
