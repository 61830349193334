import { useEffect, useState } from "react";
import { Button, Form, InputGroup} from "react-bootstrap";
import { EyeFill, Lock, PersonCircle } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { useChangePwsMutation } from "../../libs/redux/slices/Loginxxx/loginApiSlice";
import { setShowErrors, setErrorMessage, setTypeMessage } from "../../libs/redux/slices/Errorsxx/errorsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../libs/redux/slices/Loginxxx/loginSlice";

const Changpas = ({userId}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ password, setPassword] = useState(false);
  const [ passwordConf, setPasswordConf] = useState(false);
  const [ passwordOld, setPasswordOld] = useState(false);
  const [ changPasw, { isLoading }] = useChangePwsMutation();
  const { register,
    handleSubmit,
    setValue,
    formState: {
      errors
    },
  } = useForm();
  useEffect (() => {
    setValue("username", userId);
  }, [userId, setValue])

  const sendUpdate = async (formData) => {
    formData.username = userId;
    if ((formData.PasswordNew === formData.PasswordConfirm) && (formData.PasswordNew !== formData.PasswordOld)) {
      await changPasw(formData).unwrap().then(response => {
        if (!response?.errors) {
          dispatch(setShowErrors(true));
          dispatch(setErrorMessage({ message: "Se Actualizo la contraseña con Exito!" }));
          dispatch(setTypeMessage("Confirmacion"));
          dispatch(signOut());
          navigate("/");
        }

      });
    } else if (formData.PasswordNew === formData.PasswordOld) {
      dispatch(setShowErrors(true))
      dispatch(setErrorMessage({ message: "La contraseña actual debe ser diferente a la nueva" }))
      dispatch(setTypeMessage("Error"))
    } else {
      dispatch(setShowErrors(true))
      dispatch(setErrorMessage({ message: "Las contraseñas ingresada no coinciden" }))
      dispatch(setTypeMessage("Error"))
    }
  }
  return (
    <Form className="text-center w-75 mx-auto" onSubmit={handleSubmit(sendUpdate)}>
      <InputGroup className="mt-4">
        <InputGroup.Text >
          <PersonCircle />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Cedula o Correo"
          { ...register('username', { required: true, disabled: true})}
          isInvalid={errors.username}
        />
        <Form.Control.Feedback type="invalid">Debe Diligenciar el Usuario</Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-4">
        <InputGroup.Text>
          <Lock />
        </InputGroup.Text>
        <Form.Control
          type={!passwordOld ? "password" : "text"}
          placeholder="Contraseña Actual"
          { ...register('PasswordOld', { required: true})}
          isInvalid={errors.PasswordOld}
        />
        <InputGroup.Text>
          <EyeFill onClick={() => setPasswordOld(!passwordOld)}/>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">Debe Diligenciar la Contraseña Actual</Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-4">
        <InputGroup.Text>
          <Lock />
        </InputGroup.Text>
        <Form.Control
          type={!password ? "password" : "text"}
          placeholder="Nueva Contraseña"
          { ...register('PasswordNew', { required: true})}
          isInvalid={errors.PasswordNew}
        />
        <InputGroup.Text>
          <EyeFill onClick={() => setPassword(!password)}/>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">Debe Diligenciar la Nueva Contraseña</Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-4">
        <InputGroup.Text>
          <Lock />
        </InputGroup.Text>
        <Form.Control
          type={!passwordConf ? "password" : "text"}
          placeholder="Confirmar Contraseña"
          { ...register('PasswordConfirm', { required: true})}
          isInvalid={errors.PasswordConfirm}
        />
        <InputGroup.Text>
          <EyeFill onClick={() => setPasswordConf(!passwordConf)}/>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">Debe Diligenciar la Confirmacion Contraseña</Form.Control.Feedback>
      </InputGroup>
      {
        isLoading ? (
          <Button type="button" className="mt-4 mb-4 btn-login">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Cargando...
          </Button>
        ) : (
          <Button type="submit" className="mt-4 mb-4 btn-login">
            ACTUALIZAR CONTRASEÑA
          </Button>
        )
      }
    </Form>
  )
}

export default Changpas;
