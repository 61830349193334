const Loadingx = ({heigth, width}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <img style={{ background: "white" }} src={`${process.env.REACT_APP_GESTOR_WEB}/images/loading.gif`} alt="cargando..." width={`${width}`} height={`${heigth}`}></img>
        </div>
      </div>
    </div>
  );
}

export default Loadingx;
