import React from "react";
import Form from "react-bootstrap/Form";
import Autocomx from "../Autocomx";
const Metadata = ({ metadat, setJsonmetx, consult = false, defaultValue, extraFilter = "", isDisabled, jsonmetx }) => {
  let cObject = "";
  let objectMetadata = { ...metadat };
  let value = defaultValue ? defaultValue : "";
  let valueDefault = value;
  if (
    objectMetadata.METTIPXX === "LISTASELECCIONFUNCION" ||
    objectMetadata.METTIPXX === "LISTASELECCIONFUNCIONMULTIPLE"
  ) {
    let valueDef = [];
    if (typeof value === "object") {
      value.forEach((element) => {
        valueDef = [...valueDef, { id: element, label: element }];
      });
    } else {
      valueDef = value !== "" ? [...valueDef, { id: value, label: value }] : [];
    }
    valueDefault = valueDef;
  }
  switch (objectMetadata.METTIPXX) {
    case "BOOLEANO":
      cObject = (
        <Form.Check
          type="checkbox"
          onChange={(e) => {
            const key = objectMetadata.METIDXXX;
            setJsonmetx(key, e.target.value);
          }}
        ></Form.Check>
      );
      break;
    case "NUMERO":
    case "TEXTO":
      let type = objectMetadata.METTIPXX === "NUMERO" ? "number" : "text";
      cObject = (
        <>
          <Form.Control
            type={type}
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
            }}
            defaultValue={valueDefault}
          ></Form.Control>
        </>
      );
      break;
    case "FECHA":
      cObject = (
        <>
          <Form.Control
            type="date"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
              e.target.value = e.target.value.split("/").reverse().join("-");
            }}
            defaultValue={valueDefault}
          ></Form.Control>
        </>
      );
      break;
    case "LISTASELECCION":
      cObject = (
        <>
          <Form.Select
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
            }}
            defaultValue={valueDefault}
          >
            <option value={""}>[SELECCIONE]</option>
            {objectMetadata.METOPXXX.split(",").map((option, key) => {
              return (
                <option value={option} key={`option-${metadat.METIDXXX}-${key}`}>
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </>
      );
      break;
    case "LISTASELECCIONFUNCION":
      cObject = (
        <>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            defaultValue={valueDefault}
            extraFilter={extraFilter}
            isDisabled={isDisabled}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    case "LISTASELECCIONFUNCIONMULTIPLE":
      cObject = (
        <>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={!consult}
            defaultValue={valueDefault}
            extraFilter={extraFilter}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    default:
      cObject = "";
      break;
  }
  return cObject;
};

export default Metadata;
