import { useEffect, useState } from "react";
import { Container, ProgressBar, Row } from "react-bootstrap";
import { useGetPrepareDataMutation,
  useGetDownloadReportMutation,
  useGetUnionClientMutation,
  useSetUnionPartsMutation,
  useGetAddImagesMutation
 } from "../../libs/redux/slices/Repoprog/RepoprogApiSlice";

const Repoprog = ({filters, setLoadReport}) => {
  const [downReport] = useGetDownloadReportMutation();
  const [getUnionClient ] = useGetUnionClientMutation();
  const [getPreparedata ] = useGetPrepareDataMutation();
  const [setUnionParts ] = useSetUnionPartsMutation();
  const [setAddImages ] = useGetAddImagesMutation();
  const [log, setLog] = useState([]);
  const [parActual, setParActual] = useState(0);
  useEffect(() => {
    if (filters !== "") {
      const downFileReport = async (data, page, ultpage) => {
        let aLogxxx = log;
        let folder  = data[0].FOLDERXX;
        let linkZip = "";
        for (let ind = 0; ind < data.length; ind++) {
          let nIni = 0;
          let numPart  = Math.ceil(data[ind].COUNFILE/5);
          for (let i = 0; i < numPart; i++) {
            let aPendPart = [];
            try {
              await downReport({
                filters: data[ind].DOCNROXX,
                folderTm: data[ind].PATHREPO,
                offset: nIni,
                limit: 5,
              }).then((response) => {
                if (response.data.data?.PARTES) {
                  for (let part = 0; part < response.data.data.PARTES.length; part++) {
                    let obj = {
                      "PATHFILE" : data[ind].PATHREPO,
                      "IDARCHXX" : response.data.data.PARTES[part][0],
                      "TOTALPAG" : response.data.data.PARTES[part][1],
                      "TIPODOCX" : response.data.data.PARTES[part][2],
                    }
                    aPendPart.push(obj)
                    let path = data[ind].PATHREPO + "/" + response.data.data.PARTES[part][2] + ".pdf";
                    let indx = aLogxxx.indexOf(path);
                    if (indx === -1) {
                      aLogxxx.push(path);
                    }
                  }
                }
                if (response.data.data?.CREADOS?.length > 0) {
                  response.data.data?.CREADOS?.forEach(elemt => {
                    let path = data[ind].PATHREPO + "/" + elemt;
                    let indx = aLogxxx.indexOf(path);
                    if (indx === -1) {
                      aLogxxx.push(path);
                    }
                  })
                  console.log(response.data.data.urlZip)
                  linkZip = response.data.data.urlZip
                }
              });
              nIni = nIni + 5;
            } catch (error) {
            }
            for (let index = 0; index < aPendPart.length; index++) {
              console.log(aPendPart[index])
              let num  = Math.ceil(aPendPart[index].TOTALPAG/150);
              let ini = 0;
              let end = 150;
              for (let i = 0; i < num; i++) {
                try {
                  await setUnionParts({
                    PATHFILE: aPendPart[index].PATHFILE,
                    IDARCHXX: aPendPart[index].IDARCHXX,
                    TIPODOCX: aPendPart[index].TIPODOCX,
                    INICIO: ini,
                    FIN: end
                  }).then((response) => {
                    console.log(response)
                  })
                } catch ( error ) {

                }
                ini = end + 1;
                end = ini + 150;
                if (end > aPendPart[index].TOTALPAG) {
                  end = aPendPart[index].TOTALPAG
                }
              }
            }
          }
          nIni = 0;
          numPart  = Math.ceil(data[ind].COUNCLIX/5);
          for (let i = 0; i < numPart; i++) {
            try {
              await getUnionClient({
                DOCNROXX: data[ind].DOCNROXX,
                PATHFILE: data[ind].PATHREPO,
                offset: nIni,
                limit: 5,
              }).then((response) => {
                if (response.data.data.length > 0) {
                  response.data.data.forEach(elemt => {
                    let path = data[ind].PATHREPO + "/" + elemt;
                    let indx = aLogxxx.indexOf(path);
                    if (indx === -1) {
                      aLogxxx.push(path);
                    }
                  })
                }
              });
              nIni = nIni + 5;
            } catch (error) {
            }
          }
          let page = 1;
          let dataImges = await addImage(data[ind].DOCNROXX, data[ind].PATHREPO, page);
          if(dataImges > 1) {
            for (let indx = 2; indx <= dataImges; indx++) {
              let nertPage = indx;
              let nRest = await addImage(data[ind].DOCNROXX, data[ind].PATHREPO, nertPage);
            }
          }
        }
        setLog(aLogxxx)
        if (page === ultpage) {
          downZip(linkZip);
        } else {
          let pagnext = page + 1;
          prepareData(pagnext, folder);
        }
      }
      const addImage = async (aDocnro, pathfile, page) => {
        let aReturn = 0;
        try {
          await setAddImages({
            DOCNROXX: aDocnro,
            PATHFILE: pathfile,
            per_page: 16,
            page: page,
          }).then((response) => {
            if (response.data.data.data.length > 0) {
              aReturn = response.data.data.last_page;
            }
          });
        } catch (error) {
        }
        return aReturn;
      }
      const downZip = async (linkZip) => {
        if (linkZip !== "") {
          window.open(linkZip,  "_blank")
          setLoadReport(false)
        }
      }
      const prepareData = async(pag, path = "") => {
        let filts = [];
        let splitfilts = filters.split("&");
        splitfilts.forEach(element => {
          let splitFech = element.split("=");
          filts[splitFech[0]] = splitFech[1];
        });
        try {
          await getPreparedata({
            FECHINIX: filts["filters[FECHINIX]"],
            FECHFINX: filts["filters[FECHFINX]"],
            CARPFILE: path,
            per_page: 16,
            page: pag
          }).then((response) => {
            let last_page = response.data.meta.last_page;
            let bDownDo = downFileReport(response.data.data, pag, last_page);
          });
        } catch (error) {
          console.log(error)
        }
      }
      prepareData(1);
    } else {
      setLoadReport(false)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Row>
        <ProgressBar striped variant="warning" now={`${parActual}`} label={`${parActual}%`} />
      </Row>
    </Container>
  )

}

export default Repoprog;
