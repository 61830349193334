import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Modal personalizado para mostrar mensaje al cliente dentro de la busqueda de registros
 * @author Oscar Rosero <oscar.rosero@repremundo.com.co>
 * @since 2024-08-29
 */
const Modalerr = ({ handleClose, show }) => {
  console.log(show);
  return (
    <Modal show={show} onHide={() => handleClose(false)} backdrop="static">
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <strong>No se encontraron resultados</strong>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>Señor usuario la consulta realizada no arrojo datos, por favor comunique con Helpdesk.</Col>
        </Row>
        <Row>
          <Col>En caso de ser cliente repórtelo con su coordinador o director de cuenta.</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={() => handleClose(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modalerr;
