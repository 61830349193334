import { apiSlice } from "../apiSlice";

export const repoprogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrepareData: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/dataDos`,
        params: { ...parameters},
      })
    }),
    getDownloadReport: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/downloadReports`,
        params: { ...parameters }
      })
    }),
    getDownZip: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/downloadRepForZip`,
        method: "POST",
        body: {
          PATFILES: parameters.folder
        }
      })
    }),
    getUnionClient: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/DocumentByClientReport`,
        params: { ...parameters }
      })
    }),
    setUnionParts: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/unionPdfBypart`,
        params: { ...parameters }
      })
    }),
    getAddImages: builder.mutation({
      query:(parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/addImagesReport`,
        params: { ...parameters }
      })
    })
  })
})

export const {
  useGetPrepareDataMutation,
  useGetDownloadReportMutation,
  useGetDownZipMutation,
  useGetUnionClientMutation,
  useSetUnionPartsMutation,
  useGetAddImagesMutation
} = repoprogApiSlice;
