import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { getisLoggedIn, signOut } from "./libs/redux/slices/Loginxxx/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import WindowPdfViewer from './components/Trkgfile/WindowPdfViewer';
/*
*Componente principal de la aplicacion
**/
function App() {
  const isLoggedIn = useSelector(getisLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      if (!isLoggedIn) {
        dispatch(signOut());
      }
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [dispatch, isLoggedIn]);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />} />
        <Route path="login" element={!isLoggedIn ? <LoginPage /> : <Navigate to="/" />} />
        <Route path="home" element={isLoggedIn ? <HomePage /> : <Navigate to="/" />}></Route>
        <Route path="/home/:id" element={<HomePage />}></Route>
        <Route path="/home/:id/:user" element={<HomePage />}></Route>
        <Route path="/visor/:url" element={<WindowPdfViewer />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
