import './Viewerstyles.css';
import PDFViewer from './PDFViewer';
import { useParams, useLocation } from "react-router-dom";
import { getisLoggedIn, getpermUser } from "../../libs/redux/slices/Loginxxx/loginSlice";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';

const WindowPdfViewer = () => {
  const isLoggedIn = useSelector(getisLoggedIn);
  const ispermUser = useSelector(getpermUser);
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Función para descifrar los datos
  const decryptData = (encryptedData) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, 'GenioVisorPDF');
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  // Descifrar los datos de la URL
  const decryptedParams = decryptData(params.url);

  /**
   * Logica para buscar el valor del permiso de Descargar e Imprimir
   */
  let permPrint = false;
  let permDownl = false;
  if (Array.isArray(ispermUser) && ispermUser.length > 0) {
    const matchingElement = ispermUser.find(element =>
      element.EMPNITXX === decryptedParams.pEMPNITXX
    );

    if (matchingElement) {
      const areaElement = matchingElement.GEST0001.find(element1 =>
        element1.AREAIDXX.toString() === decryptedParams.pAREAIDXX.toString()
      );

      if (areaElement) {
        const serieElement = areaElement.GEST0002.find(element2 =>
          element2.SERIEIDX.toString() === decryptedParams.pSERIEIDX.toString()
        );

        if (serieElement) {
          const tipoElement = serieElement.GEST0003.find(element3 =>
            element3.TIPOIDXX.toString() === decryptedParams.pTIPOIDXX.toString()
          );

          if (tipoElement) {
            permPrint = tipoElement.PRINTXXX === 'SI' ? true : false;
            permDownl = tipoElement.DOWNLOAD === 'SI' ? true : false;
          }
        }
      }
    }
  }

  return (
    <>
      {isLoggedIn && (
        <div style={{ height: "850px" }}>
          <PDFViewer
            pdfUrl={decryptedParams.url}
            showPrint={permPrint}
            showDownload={permDownl}
          />
        </div>
      )}
      {!isLoggedIn && (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default WindowPdfViewer;
