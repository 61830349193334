import { createSlice } from "@reduxjs/toolkit";

const errorsSlice = createSlice({
  name: "errors",
  initialState: {
    showErrors: false,
    errorMessage: {},
    typeMessage:"",
    isConfirm: false,
  },
  reducers: {
    setShowErrors: (state, action) => {
      state.showErrors = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setTypeMessage: (state, action) => {
      state.typeMessage = action.payload;
    },
    setIsConfirm: (state, action) => {
      state.isConfirm = action.payload;
    },
  },
});
export const { setShowErrors, setErrorMessage, setTypeMessage, setIsConfirm } = errorsSlice.actions;
export default errorsSlice.reducer;

export const getShowErrors = (state) => {
  return state.errors.showErrors;
};

export const getErrorMessage = (state) => {
  return state.errors.errorMessage;
};

export const getTypeMessage = (state) => {
  return state.errors.typeMessage;
};

export const getIsConfirm = (state) => {
  return state.errors.isConfirm;
};
