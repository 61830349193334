import { createSlice } from "@reduxjs/toolkit";

const autocompSlice = createSlice({
  name: "autocomp",
  initialState: {
    areaSelected: "",
    empresaSelected: "",
  },
  reducers: {
    setAreaSelected: (state, action) => {
      state.areaSelected = action.payload;
    },
    setEmpresaSelected: (state, action) => {
      state.empresaSelected = action.payload;
    }
  },
});

export const {
  setAreaSelected,
  setEmpresaSelected
} = autocompSlice.actions;

export default autocompSlice.reducer;

export const getAreaSelected = (state) => {
  return state.autocomp.areaSelected;
};
export const getEmpresaSelected = (state) => {
  return state.autocomp.empresaSelected;
};
